// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Countdown
\* ------------------------------------------------------------------------ */

.countdown {
    & {
        height: remify(100, 16);
        position: relative;
        width: remify(100, 16);
    }
}

.countdown_days {
    & {
        left: 50%;
        margin-top: remify(4, 16);
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 1;
    }
}

.countdown_text {
    & {
        color: $secondary;
        font-size: remify(18, 16);
        letter-spacing: remify(2.7, 18);
        line-height: remify(26, 18);
    }

    .text_featured {
        font-size: remify(34, 18);
        letter-spacing: 0;
    }
}

.countdown_svg {
    & {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transform: rotate(270deg);
        width: 100%;
        z-index: 0;
    }
}

.countdown_svg_background {
    & {
        fill: transparent;
        stroke: transparentize($light, (2 / 3));
        stroke-width: remify(2, 16);
    }
}

.countdown_svg_foreground {
    & {
        fill: transparent;
        stroke: $light;
        stroke-width: remify(3, 16);
    }
}
