// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Text
\* ------------------------------------------------------------------------ */

.text {
    & {
        color: $foreground;
        display: block;
        font-display: swap;
        font-family: $body-font;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(18, 16) remify(26, 16);
        font-weight: 400;
        line-height: remify(26, 18);
        margin: remify(0 0 26, 18);
    }

    .button {
        font-size: 1em;
    }

    .button.-small {
        font-size: remify(20, 18);
    }
}

/* small variant */

.text.-small {
    & {
        font-size: remify(20, 16);
    }
}

/* label variant */

.text.-label {
    & {
        cursor: pointer;
    }
}

/* list variant */

.text.-list {
    & {
        padding: remify(0 0 0 24, 16);
    }

    .text_list-item {
        display: list-item;
    }

    .text_list-item:not(:last-child) {
        margin-bottom: remify(8, 16);
    }
}

/* list ordered variant */

.text.-list.-ordered {
    & {
        list-style: decimal;
    }
}

/* list unordered variant */

.text.-list.-unordered {
    & {
        list-style: disc;
    }
}

/* table variant */

.text_table_container {
    & {
        overflow: auto;
        width: 100%;
    }
}

.text.-table {
    & {
        border-collapse: collapse;
        display: table;
        width: 100%;
    }

    .text_header {
        display: table-header-group;
    }

    .text_body {
        display: table-row-group;
    }

    .text_footer {
        color: $foreground_alt;
        display: table-footer-group;
        font-style: italic;
    }

    .text_row {
        display: table-row;
    }

    .text_cell {
        border: 0;
        padding: remify(6 8, 16);
        text-align: left;
        vertical-align: top;
    }

    .text_header .text_cell {
        border-bottom: remify(2) solid $background_alt;
        font-weight: 700;
    }

    .text_body .text_cell.-header {
        font-weight: 700;
        text-align: left;
    }

    > .text_row:nth-child(even) .text_cell,
    .text_body .text_row:nth-child(even) .text_cell {
        background: $background_alt;
    }
}
