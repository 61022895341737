// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Button
\* ------------------------------------------------------------------------ */

.button {
    & {
        background: $secondary;
        border: 0;
        border-radius: remify(21, 18);
        color: $accent;
        cursor: pointer;
        display: inline-block;
        font-display: swap;
        font-family: $body-font;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(18, 16) remify(26, 16);
        font-weight: 700;
        line-height: remify(26, 18);
        margin: remify(0 0 20, 18);
        outline: 0;
        padding: remify(14.5 22.5, 18);
        text-align: center;
        text-decoration: none;
        transition: background-color 0.15s, color 0.15s;
    }

    &:focus,
    &:hover {
        background-color: darken($secondary, 5);
        color: darken($accent, 5);
    }
}

/* alt variant */

.button.-alt {
    & {
        background-color: $primary_alt;
        color: $light;
    }

    &:focus,
    &:hover {
        background-color: darken($primary_alt, 5);
        color: $light;
    }
}

/* small variant */

.button.-small {
    & {
        font-size: remify(20, 16);
    }
}

/* icon variant */

.button.-icon {
    & {
        background: none;
        font-size: remify(20, 16);
        line-height: remify(29, 20);
        padding: 0;
        text-transform: lowercase;
    }

    .button_icon {
        align-items: center;
        background: $accent;
        border-radius: remify(52, 52);
        color: $secondary;
        display: flex;
        font-size: remify(52, 20);
        height: remify(104, 52);
        justify-content: center;
        margin: remify(0 auto 10.4, 52);
        transition: background-color 0.15s;
        width: remify(104, 52);
    }

    &:focus .button_icon,
    &:hover .button_icon {
        background-color: darken($accent, 5);
    }

    .button_icon .icon_svg {
        position: static;
    }
}
