// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    & {
        @include layer("footer");
    }

    @media screen and (max-width: $xs-break - (1 / 16)) {
        text-align: center;
    }
}

.footer_inner {
    & {
        background: $page_background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 2.4) ($content-padding * 0.8));
    }
}

.footer_link {
    & {
        color: $secondary;
    }

    &:focus,
    &:hover {
        color: darken($secondary, 10);
    }
}

.footer_text {
    & {
        font-range: $xs-break $xl-break;
        font-size: responsive remify(17, 16) remify(27, 16);
    }

    &.-small {
        font-size: remify(17, 16);
    }
}

.footer_divider {
    & {
        background: $page_background_alt;
    }
}

.footer_logo {
    & {
        margin: remify(0 auto ($content-padding * 1.2), 16);
        max-width: remify(215, 16);
    }
}

.footer_menu-list_container {
    .menu-list.-social {
        font-range: $xs-break $xl-break;
        font-size: responsive remify(21.5, 16) remify(34, 16);
    }

    .menu-list.-social .menu-list_item:not(:last-child) {
        margin-right: remify($content-padding * 0.7352941176470588, 21.5);
    }

    .menu-list.-social .menu-list_link {
        background: none;
        padding: 0;
        transition: color 0.15s;
    }

    .menu-list.-social .menu-list_link:focus,
    .menu-list.-social .menu-list_link:hover {
        color: darken($secondary, 10);
    }
}

/* fullbleed variant */

.footer-block.-fullbleed {
    & {
        background: $page_background;
        padding: remify(($content-padding * 2.4) ($content-padding * 0.8));
    }

    > .footer_inner {
        background: none;
        padding: 0;
    }

    > .footer_inner.-fullbleed {
        max-width: none;
    }
}
