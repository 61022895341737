// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Tribe Events Bar
\* ------------------------------------------------------------------------ */

/* stylelint-disable no-descending-specificity, selector-class-pattern, selector-max-id, selector-no-qualifying-type, selector-max-type */

/* Tribe is stupidly specific with their classes... */

.tribe-events-bar {
    & {
        background: $background_alt;
        display: flex;
        flex-wrap: wrap;
        margin: remify(0 0 20, 16);
    }
}

.tribe-events-bar_text {
    & {
        font-size: remify(12, 16);
        font-weight: 700;
        margin: 0;
        text-transform: uppercase;
    }
}

.tribe-events-bar_input {
    & {
        font-size: remify(12, 16);
        margin-bottom: remify(10, 12);
    }
}

.tribe-events-bar_button {
    & {
        display: block;
        font-size: remify(14, 16);
        margin-bottom: 0;
        padding: remify(6.5 10, 14);
        width: 100%;
    }
}

.tribe-events-bar_toggle {
    & {
        background: $background_alt;
        border: 0;
        flex: 1 1 auto;
        font-family: $heading-font;
        font-size: remify(12, 16);
        font-weight: 700;
        margin: 0;
        padding: remify(20, 12);
        position: relative;
        text-align: left;
        text-transform: uppercase;
        transition: background-color 0.15s;
    }

    &:focus,
    &:hover {
        background-color: darken($background_alt, 5);
    }

    &::after {
        border-left: remify(6, 12) solid transparent;
        border-right: remify(6, 12) solid transparent;
        border-top: remify(6, 12) solid $dark;
        content: "\0020";
        display: block;
        position: absolute;
        right: remify(20, 12);
        top: 50%;
        transform: translateY(-50%);
        transition: transform 0.15s;
    }

    &.tribe-bar-filters-open::after {
        transform: rotate(-180deg);
    }
}

.tribe-events-bar_views {
    & {
        background: darken($background_alt, 10);
        flex: none;
        min-width: remify(150, 16);
        padding: remify(10 20, 16);
        position: relative;
        width: 100%;
    }

    // Tribe generates these classes so I can't change them:

    .tribe-bar-views-list {
        background: inherit;
        color: $foreground;
        font-family: $body-font;
        font-size: remify(14, 16);
        font-weight: 400;
        left: 0;
        list-style: none;
        margin: 0;
        padding: 0;
        padding: remify(0 20 10, 14);
        position: absolute;
        right: 0;
        white-space: nowrap;
        z-index: 999;
    }

    &:not(.tribe-bar-views-open) .tribe-bar-views-option:not(.tribe-bar-active) {
        display: none;
    }

    .tribe-bar-views-option a {
        color: $foreground;
        text-decoration: none;
    }

    .tribe-bar-views-option a {
        background: left center / remify(14, 14) no-repeat;
        padding-left: remify(18, 14);
    }

    .tribe-bar-views-option.tribe-bar-views-option-month a {
        /* bgImage: ignore */
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M30.9,6.9v22.9c0,0.6-0.2,1.2-0.7,1.6c-0.5,0.5-1,0.7-1.6,0.7H3.4c-0.6,0-1.2-0.2-1.6-0.7c-0.5-0.5-0.7-1-0.7-1.6V6.9c0-0.6,0.2-1.2,0.7-1.6c0.5-0.5,1-0.7,1.6-0.7h2.3V2.9c0-0.8,0.3-1.5,0.8-2C7.1,0.3,7.8,0,8.6,0h1.1c0.8,0,1.5,0.3,2,0.8c0.6,0.6,0.8,1.2,0.8,2v1.7h6.9V2.9c0-0.8,0.3-1.5,0.8-2c0.6-0.6,1.2-0.8,2-0.8h1.1c0.8,0,1.5,0.3,2,0.8c0.6,0.6,0.8,1.2,0.8,2v1.7h2.3c0.6,0,1.2,0.2,1.6,0.7C30.6,5.7,30.9,6.2,30.9,6.9z M3.4,16.6h5.1v-5.1H3.4V16.6z M3.4,23.4h5.1v-5.7H3.4V23.4z M3.4,29.7h5.1v-5.1H3.4V29.7z M10.3,8V2.9c0-0.2-0.1-0.3-0.2-0.4C10,2.3,9.9,2.3,9.7,2.3H8.6c-0.2,0-0.3,0.1-0.4,0.2C8.1,2.6,8,2.7,8,2.9V8c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.4,0.2h1.1c0.2,0,0.3-0.1,0.4-0.2C10.2,8.3,10.3,8.2,10.3,8z M9.7,16.6h5.7v-5.1H9.7V16.6z M9.7,23.4h5.7v-5.7H9.7V23.4z M9.7,29.7h5.7v-5.1H9.7V29.7z M16.6,16.6h5.7v-5.1h-5.7V16.6z M16.6,23.4h5.7v-5.7h-5.7V23.4zM16.6,29.7h5.7v-5.1h-5.7V29.7z M24,8V2.9c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-1.1c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.2,0.4V8c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.4,0.2h1.1c0.2,0,0.3-0.1,0.4-0.2C23.9,8.3,24,8.2,24,8zM23.4,16.6h5.1v-5.1h-5.1V16.6z M23.4,23.4h5.1v-5.7h-5.1V23.4z M23.4,29.7h5.1v-5.1h-5.1V29.7z'/%3E%3C/svg%3E%0A");
    }

    .tribe-bar-views-option.tribe-bar-views-option-list a {
        /* bgImage: ignore */
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M4.6,4v3.4c0,0.2-0.1,0.3-0.2,0.4C4.3,7.9,4.2,8,4,8H0.6C0.4,8,0.3,7.9,0.2,7.8C0.1,7.7,0,7.6,0,7.4V4 c0-0.2,0.1-0.3,0.2-0.4s0.2-0.2,0.4-0.2H4c0.2,0,0.3,0.1,0.4,0.2C4.5,3.7,4.6,3.8,4.6,4z M4.6,10.9v3.4c0,0.2-0.1,0.3-0.2,0.4 c-0.1,0.1-0.2,0.2-0.4,0.2H0.6c-0.2,0-0.3-0.1-0.4-0.2C0.1,14.6,0,14.4,0,14.3v-3.4c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.2 H4c0.2,0,0.3,0.1,0.4,0.2C4.5,10.6,4.6,10.7,4.6,10.9z M4.6,17.7v3.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.2H0.6 c-0.2,0-0.3-0.1-0.4-0.2C0.1,21.4,0,21.3,0,21.1v-3.4c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.2H4c0.2,0,0.3,0.1,0.4,0.2 C4.5,17.4,4.6,17.6,4.6,17.7z M4.6,24.6V28c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.2H0.6c-0.2,0-0.3-0.1-0.4-0.2 S0,28.2,0,28v-3.4c0-0.2,0.1-0.3,0.2-0.4C0.3,24.1,0.4,24,0.6,24H4c0.2,0,0.3,0.1,0.4,0.2C4.5,24.3,4.6,24.4,4.6,24.6z M32,4v3.4 c0,0.2-0.1,0.3-0.2,0.4C31.7,7.9,31.6,8,31.4,8h-24C7.3,8,7.1,7.9,7,7.8C6.9,7.7,6.9,7.6,6.9,7.4V4c0-0.2,0.1-0.3,0.2-0.4 c0.1-0.1,0.2-0.2,0.4-0.2h24c0.2,0,0.3,0.1,0.4,0.2C31.9,3.7,32,3.8,32,4z M32,10.9v3.4c0,0.2-0.1,0.3-0.2,0.4 c-0.1,0.1-0.2,0.2-0.4,0.2h-24c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.4v-3.4c0-0.2,0.1-0.3,0.2-0.4 c0.1-0.1,0.2-0.2,0.4-0.2h24c0.2,0,0.3,0.1,0.4,0.2C31.9,10.6,32,10.7,32,10.9z M32,17.7v3.4c0,0.2-0.1,0.3-0.2,0.4 c-0.1,0.1-0.2,0.2-0.4,0.2h-24c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.4v-3.4c0-0.2,0.1-0.3,0.2-0.4 c0.1-0.1,0.2-0.2,0.4-0.2h24c0.2,0,0.3,0.1,0.4,0.2C31.9,17.4,32,17.6,32,17.7z M32,24.6V28c0,0.2-0.1,0.3-0.2,0.4 c-0.1,0.1-0.2,0.2-0.4,0.2h-24c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.4v-3.4c0-0.2,0.1-0.3,0.2-0.4 C7.1,24.1,7.3,24,7.4,24h24c0.2,0,0.3,0.1,0.4,0.2C31.9,24.3,32,24.4,32,24.6z' /%3E%3C/svg%3E%0A");
    }

    .tribe-bar-views-option.tribe-bar-views-option-day a {
        /* bgImage: ignore */
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M30.9,6.9v22.9c0,0.6-0.2,1.2-0.7,1.6c-0.5,0.5-1,0.7-1.6,0.7H3.4c-0.6,0-1.2-0.2-1.6-0.7c-0.5-0.5-0.7-1-0.7-1.6V6.9 c0-0.6,0.2-1.2,0.7-1.6c0.5-0.5,1-0.7,1.6-0.7h2.3V2.9c0-0.8,0.3-1.5,0.8-2C7.1,0.3,7.8,0,8.6,0h1.1c0.8,0,1.5,0.3,2,0.8 c0.6,0.6,0.8,1.2,0.8,2v1.7h6.9V2.9c0-0.8,0.3-1.5,0.8-2c0.6-0.6,1.2-0.8,2-0.8h1.1c0.8,0,1.5,0.3,2,0.8c0.6,0.6,0.8,1.2,0.8,2v1.7 h2.3c0.6,0,1.2,0.2,1.6,0.7C30.6,5.7,30.9,6.2,30.9,6.9z M3.4,29.7h25.1V11.4H3.4V29.7z M10.3,8V2.9c0-0.2-0.1-0.3-0.2-0.4 C10,2.3,9.9,2.3,9.7,2.3H8.6c-0.2,0-0.3,0.1-0.4,0.2C8.1,2.6,8,2.7,8,2.9V8c0,0.2,0.1,0.3,0.2,0.4s0.2,0.2,0.4,0.2h1.1 c0.2,0,0.3-0.1,0.4-0.2C10.2,8.3,10.3,8.2,10.3,8z M24.4,17.2l-9.1,9.1c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2l-5.1-5.1 c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4l0.8-0.8c0.1-0.1,0.2-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2l3.9,3.9l7.9-7.9 c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2l0.8,0.8c0.1,0.1,0.2,0.2,0.2,0.4S24.5,17.1,24.4,17.2z M24,8V2.9 c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-1.1c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.2,0.4V8c0,0.2,0.1,0.3,0.2,0.4 s0.2,0.2,0.4,0.2h1.1c0.2,0,0.3-0.1,0.4-0.2S24,8.2,24,8z' /%3E%3C/svg%3E%0A");
    }
}

.tribe-events-bar_filters {
    & {
        display: none;
        flex: 1 1 100%;
        padding: remify(0 20, 16);
    }

    &::before,
    &::after {
        clear: both;
        content: "\0020";
        display: block;
        height: remify(20, 16);
        width: 100%;
    }
}
