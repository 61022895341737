// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern */

// Vendor

@import "../../vendor/_swiper";

// Custom

.swiper-slide {
    & {
        background: lighten($dark, 6.6667);
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: auto;
        margin: 0;
        overflow: hidden;
    }
}

.swiper-picture,
.swiper-picture {
    & {
        flex: none;
    }
}

.swiper-image {
    & {
        display: block;
        width: 100%;
    }

    .swiper-container.-fixedheight & {
        left: 50%;
        min-width: 100%;
        position: relative;
        transform: translateX(-50%);
        width: auto;
    }
}

.swiper-caption {
    & {
        flex: 1 1 auto;
        margin: 0 auto;
        max-width: remify($site-width);
    }

    .swiper-caption-inner {
        background: lighten($dark, 6.6667);
        max-width: remify($site-width * 0.6805555555555556);
        padding: remify(($content-padding * 0.88) ($content-padding * 0.8) ($content-padding * 3.24));
    }
}

.swiper-title {
    & {
        color: $secondary;
        margin-bottom: 0;
    }
}

.swiper-user-content {
    .user-content_text {
        color: $secondary;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(18, 16) remify(30, 16);
    }

    > :last-child {
        margin-bottom: 0 !important;
    }
}

.swiper-button {
    & {
        background: none;
        border: 0;
        color: $primary;
        display: block;
        height: 100%;
        margin: 0;
        opacity: 0;
        padding: 0;
        top: 0;
        transition: opacity 0.15s;
        width: remify(55, 16);
    }

    &.-prev {
        background: none;
        left: 0;
    }

    .swiper-container-rtl &.-prev {
        background: none;
        right: 0;
    }

    &.-next {
        background: none;
        right: 0;
    }

    .swiper-container-rtl &.-next {
        background: none;
        left: 0;
    }

    .swiper-container:hover & {
        opacity: 0.5;
    }

    .swiper-container:hover &:hover,
    .swiper-container:hover &:focus {
        opacity: 1;
    }

    .swiper-button-icon,
    .swiper-button-icon {
        font-size: remify(48, 16);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
    & {
        bottom: auto;
        top: 0;
    }
}

.swiper-pagination-bullet {
    & {
        background: transparentize($dark, 0.5);
        height: remify(10, 16);
        opacity: 1;
        transition: background-color 0.15s;
        width: remify(10, 16);
    }
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    & {
        margin: remify(20 5, 16);
    }
}

.swiper-pagination-bullet-active {
    & {
        background-color: $primary;
    }
}

/* sponsors variant */

.swiper-container.-sponsors {
    & {
        margin-left: remify($content-padding * -1.2);
        width: calc(100% + #{remify(($content-padding * 1.2) * 2, 16)});
    }

    &::before,
    &::after {
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        width: remify(($content-padding * 1.2) * 4);
        z-index: 999;
    }

    &::before {
        background: linear-gradient(to right, $background, ease, transparentize($background, 1));
        left: 0;
    }

    &::after {
        background: linear-gradient(to left, $background, ease, transparentize($background, 1));
        right: 0;
    }

    .swiper-slide {
        background: $background;
        justify-content: center;
    }

    .swiper_logo {
        padding: remify(($content-padding * 0.8) ($content-padding * 1.2), 16);
    }
}
