// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Title
\* ------------------------------------------------------------------------ */

.title {
    &,
    &.-h1 {
        color: $primary;
        font-display: swap;
        font-family: $heading-font;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(28, 16) remify(57, 16);
        font-weight: 700;
        line-height: remify(37, 28);
        margin: remify(0 0 29, 28);
    }

    &.-h2 {
        font-range: $xs-break $xl-break;
        font-size: responsive remify(30, 16) remify(46, 16);
        line-height: remify(36, 30);
        margin: remify(0 0 14, 30);
    }

    &.-h3 {
        font-range: $xs-break $xl-break;
        font-size: responsive remify(20, 16) remify(36, 16);
        line-height: remify(27, 20);
        margin: remify(0 0 19, 20);
    }

    &.-h4 {
        font-size: remify(30, 16);
        line-height: remify(36, 30);
        margin: remify(0 0 14, 30);
    }

    &.-h5,
    &.-h6 {
        font-family: $body-font;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(24, 16) remify(27, 16);
        font-weight: 400;
        letter-spacing: remify(3.65, 24);
        line-height: remify(30, 24);
        margin: 0;
        text-transform: uppercase;
    }

    &.-h6 {
        color: $accent;
        font-size: remify(24, 16);
    }
}

/* divider variant */

.title.-divider {
    & {
        align-items: center;
        display: flex;
    }

    &::before,
    &::after {
        background: currentColor;
        color: inherit;
        content: "\0020";
        display: block;
        flex: 1 1 0%;
        height: remify(2);
    }

    &::before {
        margin-right: remify(16, 16);
    }

    &:not(.-full)::before {
        display: none;
    }

    &::after {
        margin-left: remify(16, 16);
    }
}
