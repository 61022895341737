// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Alert
\* ------------------------------------------------------------------------ */

.alert-block {
    & {
        @include layer("alert");
    }

    & {
        background: $warning;
        display: none;
        padding: remify($content-padding);
    }

    &.is-active {
        display: block;
    }
}

.alert_inner {
    & {
        margin: 0 auto;
        max-width: remify($site-width);
    }
}

.alert_button {
    & {
        align-items: center;
        background: $light;
        border: 0;
        border-radius: 50%;
        color: $warning;
        cursor: pointer;
        display: inline-flex;
        height: remify(32, 16);
        justify-content: center;
        margin: remify(0 0 ($content-padding * 0.5));
        padding: 0;
        width: remify(32, 16);
    }

    @media screen and (min-width: $xs-break) {
        margin-bottom: 0;
    }

    .button_icon {
        margin-top: remify(-3, 16);
    }
}

.alert_user-content {
    > :last-child {
        margin-bottom: 0 !important;
    }
}
