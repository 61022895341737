// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Divider
\* ------------------------------------------------------------------------ */

.divider {
    & {
        background: $primary;
        border: 0;
        clear: both;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(16, 16) remify(28, 16);
        height: remify(2);
        margin: remify(0 0 16, 16);
        width: 100%;
    }
}
