// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Article
\* ------------------------------------------------------------------------ */

.article_figure {
    & {
        margin: remify(0 0 30, 16);
    }
}

.article_image {
    & {
        display: block;
        width: 100%;
    }
}

/* event-excerpt variant */

.article.-event-excerpt {
    & {
        background: $background;
        margin-bottom: remify($content-padding);
    }

    .article_figure {
        font-range: $xs-break $xl-break;
        font-size: responsive remify(16, 16) remify(21.875, 16);
        margin-bottom: remify(14, 16);
    }

    .article_title {
        font-family: $body-font;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(16, 16) remify(26, 16);
        line-height: rmeify(19, 16);
        margin-bottom: remify(2, 16);
    }

    .article_text {
        font-range: $xs-break $xl-break;
        font-size: responsive remify(16, 16) remify(20, 16);
        margin-bottom: remify(16, 16);
    }

    .article_header .article_text {
        font-range: $xs-break $xl-break;
        font-size: responsive remify(14, 16) remify(18, 16);
    }

    .article_header,
    .article_content {
        padding: remify($content-padding * 0.4);
    }
}

/* excerpt variant */

.article.-excerpt {
    &:not(:last-of-type) {
        border-bottom: remify(1) solid #1D3148;
        margin-bottom: remify(25, 16);
    }

    .article_title {
        font-range: $xs-break $xl-break;
        font-size: responsive remify(24, 16) remify(32, 16);
        margin-bottom: remify(7.5, 24);
    }

    .article_header > .article_title:not(:last-child) {
        margin-bottom: 0;
    }

    .article_header > .article_title:not(:last-child) + * {
        margin-bottom: remify(10, 16);
    }
}
