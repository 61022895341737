// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

.widget {
    & {
        background: $secondary;
    }
}

.widget_figure {
    margin: 0;
    position: relative;
}

.widget_image {
    & {
        display: block;
        margin: 0 auto;
        max-width: remify(150, 16);
        position: relative;
        z-index: 1;
    }
}

.widget_content {
    & {
        padding: remify(($content-padding * 0.6) ($content-padding * 0.8));
    }
}

.widget_menu-list_container {
    .menu-list.-submenu {
        margin-bottom: remify(12, 27);
    }
}

/* alt variant */

.widget.-alt {
    & {
        background: transparentize($primary_alt, 0.8);
    }

    .widget_text {
        font-size: remify(18, 16);
    }

    @media screen and (max-width: $xs-break - (1 / 16)) {
        @supports (width: 100vw) {
            left: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            position: relative;
            right: 50%;
            width: 100vw;
        }
    }
}

/* alt2 variant */

.widget.-alt2 {
    & {
        background: $primary;
        background: linear-gradient(to bottom, transparentize($primary, 0.15) 10%, transparentize($primary, 0.3) 100%), url("../media/background-callout.jpg") center center / cover no-repeat;
    }

    .widget_user-content .user-content_text {
        color: $light;
    }

    @media screen and (max-width: $xs-break - (1 / 16)) {
        @supports (width: 100vw) {
            left: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            padding-top: remify(32, 16);
            position: relative;
            right: 50%;
            width: 100vw;
        }

        .widget_title.-divider {
            justify-content: center;
        }

        .widget_title.-divider::after {
            display: none;
        }

        .widget_title.-h2 {
            text-align: center;
        }

        .widget_header {
            padding: remify(($content-padding * 0.6) ($content-padding * 0.8));
        }
    }
}
