// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block_container {
    & {
        background: $primary;
    }
}

.header-block {
    & {
        @include layer("header");
    }
}

.header_inner {
    & {
        background: $accent;
        margin: 0 auto;
        max-width: remify($site-width);
    }
}

.header_logo {
    & {
        padding: remify($content-padding ($content-padding * 0.8));
        width: calc(#{remify(205, 16)} + #{remify($content-padding * 0.8)});
    }
}

.header_search-form_container {
    & {
        font-size: remify(16); // don't scale this!
        padding: remify(0 30, 16);
    }

    .search-form .search-form_input {
        background-color: $accent_alt;
        border-color: $accent;
        color: $light;
    }

    .search-form .search-form_button {
        color: $accent;
    }
}

.header_info-box {
    & {
        align-items: center;
        background: $primary;
        display: flex;
        justify-content: center;
    }
}

.header_info-box_figure {
    & {
        flex: 0 0 auto;
        margin: remify(0 -24 0 0, 16);
        position: relative;
        width: remify(130, 16);
    }
}

.header_info-box_image {
    & {
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: remify(88, 16);
    }
}

.header_info-box_content {
    & {
        flex: 0 0 auto;
        position: relative;
    }
}

.header_info-box_title {
    & {
        color: $light;
        font-size: remify(38, 16);
        margin-bottom: remify(45, 38);
    }
}

.header_info-box_text {
    & {
        color: $secondary;
        font-size: remify(20, 16);
        line-height: 0;
        margin-bottom: 0;
        margin-top: remify(27, 20);
    }
}

.header_info-box_countdown {
    @media screen and (max-width: $xs-break - (1 / 16)) {
        & {
            background: $primary;
            border-radius: 50%;
            font-size: remify(12, 16);
            left: 50%;
            position: absolute;
            top: 100%;
            transform: translateX(-50%) translateY(-60%);
        }

        .countdown_svg {
            font-size: remify(11.5, 12);
        }
    }
}

/* fullbleed variant */

.header-block.-fullbleed {
    & {
        background: $accent;
    }

    > .header_inner {
        background: none;
    }

    > .header_inner.-fullbleed {
        max-width: none;
    }
}
