// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Tribe Events General
\* ------------------------------------------------------------------------ */

/* stylelint-disable no-descending-specificity, selector-class-pattern, selector-max-id, selector-no-qualifying-type, selector-max-type */

/* Tribe is stupidly specific with their classes... */

/* Button */

.tribe-events-button {
    & {
        @extend .button;
    }
}

/* Loader */

.tribe-events-ajax-loading {
    & {
        display: none;
    }
}

/* Content */

#tribe-events-content {
    & {
        margin: 0;
        padding: 0;
    }
}

/* Footer */

#tribe-events--footer {
    & {
        margin: remify(20 0 18, 16);
    }
}
