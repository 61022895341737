// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Input
\* ------------------------------------------------------------------------ */

.input {
    & {
        appearance: none;
        background: $background;
        border: remify(1) solid darken($background_alt, 10);
        border-radius: 0;
        color: $foreground;
        display: block;
        font-display: swap;
        font-family: $body-font;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(12, 16) remify(16, 16);
        font-weight: 400;
        line-height: remify(18, 12);
        margin: remify(0 0 18, 12);
        outline: 0;
        padding: remify(6 8, 12);
        transition: border-color 0.15s, color 0.15s;
        width: 100%;
    }

    &:focus,
    &:hover {
        border-color: $accent;
    }
}

/* select variant */

.input.-select {
    & {
        cursor: pointer;

        /* stylelint-disable order/properties-alphabetical-order, property-no-vendor-prefix */

        // remove Safari, Chrome, and Firefox default styles & arrow
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        // remove Firefox default styles & arrow
        text-indent: 0.01px;
        text-overflow: "";
        // remove IE default styles & arrow
        &::-ms-expand { display: none; }

        /* stylelint-enable order/properties-alphabetical-order, property-no-vendor-prefix */
    }

    &.-arrow {
        background-image: url("data:image/svg+xml;utf8,<svg width='1792' height='1792' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'><path d='M1408 1088q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45zm0-384q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z'/></svg>");
        background-position: center right remify(8, 12);
        background-repeat: no-repeat;
        background-size: remify(12 12, 12);
        padding-right: remify(30, 12);
    }
}

/* checkbox & radiobutton variants */

.input.-checkbox,
.input.-radiobutton {
    & {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: remify(10, 12);
        cursor: pointer;
        display: inline-block;
        height: remify(16, 12);
        padding: 0;
        vertical-align: middle;
        width: remify(16, 12);
    }

    &.-radiobutton {
        border-radius: 50%;
    }

    &.-checkbox:checked {
        /* bgImage: ignore */
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23000000' d='M28.6,3.9L10.2,22.3l-6.8-6.8c-0.3-0.3-0.9-0.3-1.2,0l-2,2c-0.3,0.3-0.3,0.9,0,1.2l9.3,9.3c0.3,0.3,0.9,0.3,1.2,0l21-21 c0.3-0.3,0.3-0.9,0-1.2l-2-2C29.4,3.6,28.9,3.6,28.6,3.9z' /%3E%3C/svg%3E%0A");
    }

    &.-radiobutton:checked {
        /* bgImage: ignore */
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23000000' d='M16,0C7.2,0,0,7.2,0,16s7.2,16,16,16s16-7.2,16-16S24.8,0,16,0z' /%3E%3C/svg%3E%0A");
    }

    & + .text.-label {
        display: inline-block;
    }
}

/* touch checkbox & radiobutton variants */

@media (pointer: coarse) {
    .input.-checkbox,
    .input.-radiobutton {
        & {
            font-size: remify(24, 16);
        }
    }
}
