// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Gallery
\* ------------------------------------------------------------------------ */

.gallery {
    & {
        display: flex;
        flex-wrap: wrap;
    }
}

.gallery-item {
    & {
        margin: 0;
    }

    @for $i from 1 through 6 {
        .gallery-columns-#{$i} & {
            flex: 1 1 50%;
        }
    }
}
