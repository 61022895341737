// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Module
\* ------------------------------------------------------------------------ */

@import "../module/tribe-events-bar/_tribe-events-bar_xxxs";

/* ------------------------------------------------------------------------ *\
 * Layout
\* ------------------------------------------------------------------------ */
