// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-block {
    & {
        @include layer("content");
    }
}

.content_inner {
    & {
        background: $background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 1.2) ($content-padding * 0.8));
    }
}

.content_widget {
    &:not(:last-child) {
        margin-bottom: remify($content-padding);
    }
}

.content_swiper-container {
    &.-sponsors {
        @media screen and (max-width: remify($site-width + (($content-padding * 1.2) * 2), 16)) {
            margin-left: 0;
            width: 100%;
        }
    }
}

/* fullbleed variant */

.content-block.-fullbleed {
    & {
        background: $background;
        padding: remify(($content-padding * 1.2) ($content-padding * 0.8));
    }

    > .content_inner {
        background: none;
        padding: 0;
    }

    > .content_inner.-fullbleed {
        max-width: none;
    }
}

/* alt variant */

.content-block.-alt {
    > .content_inner {
        background: $secondary;
    }
}

/* alt fullbleed variant */

.content-block.-alt.-fullbleed {
    & {
        background: $secondary;
    }

    > .content_inner {
        background: none;
    }
}
