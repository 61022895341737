// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero-block {
    & {
        @include layer("hero");
    }
}

.hero_inner {
    & {
        background: $background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 0.75) $content-padding);
    }
}

.hero_swiper-container {
    .swiper-image {
        font-range: $xxxs-break $xxxl-break;
        font-size: responsive remify(16, 16) remify(54.15, 16);
        height: remify(260, 16);
    }

    .swiper-caption {
        @media screen and (max-width: $xs-break - (1 / 16)) {
            text-align: center;
        }
    }
}

/* fullbleed variant */

.hero-block.-fullbleed {
    & {
        background: $background;
        padding: remify(($content-padding * 0.75) $content-padding);
    }

    > .hero_inner {
        background: none;
        padding: 0;
    }

    > .hero_inner.-fullbleed {
        max-width: none;
    }
}

/* short variant */

.hero-block.-short {
    .hero_swiper-container .swiper-slide::before {
        background-image: linear-gradient(to bottom, transparentize($dark, 1) 60%, $dark 90%);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        opacity: 0.6;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
    }

    .hero_swiper-container .swiper-image {
        font-range: $xxxs-break $xxxl-break;
        font-size: responsive remify(16, 16) remify(54.15, 16);
        height: remify(180, 16);
    }

    .hero_swiper-container .swiper-caption {
        & {
            bottom: 0;
            left: 0;
            max-width: none;
            padding: remify(($content-padding * 0.6) ($content-padding * 0.8));
            position: absolute;
            width: 100%;
            z-index: 2;
        }

        @media screen and (max-width: $xs-break - (1 / 16)) {
            text-align: left;
        }
    }

    .hero_swiper-container .swiper-caption .swiper-caption-inner {
        background: none;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: 0;
    }
}
