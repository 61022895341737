// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Menu List
\* ------------------------------------------------------------------------ */

/* ============================ GLOBAL VARIANTS =========================== */

.menu-list_container {
    & {
        clear: fix;
    }
}

.menu-list {
    & {
        clear: fix;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        text-align: center;
    }
}

.menu-list_item {
    & {
        float: left;
        position: relative;
    }

    &.-parent {
        display: flex;
        flex-wrap: wrap;
    }
}

.menu-list_link {
    & {
        display: block;
        flex: 1 1 1%;
        min-width: 0;
        text-decoration: none;
    }
}

.menu-list_toggle {
    & {
        background: none;
        border: 0;
        cursor: pointer;
        display: block;
        flex: 0 0 auto;
        font-size: 1em;
        margin: 0;
    }

    > .icon {
        transition: transform 0.15s;
    }

    .menu-list_item.is-active > & > .icon {
        transform: rotate(-180deg);
    }
}

/* between variant */

.menu-list.-between {
    & {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    > .menu-list_item {
        flex: 0 0 auto;
        float: none;
    }

    &::after {
        flex: 0 0 100%;
    }
}

/* center variant */

.menu-list.-center {
    & {
        float: right;
        position: relative;
        right: 50%;
    }

    > .menu-list_item {
        left: 50%;
    }
}

/* flex variant */

.menu-list.-flex {
    & {
        display: flex;
        justify-content: space-between;
    }

    &::after {
        display: none;
    }

    > .menu-list_item {
        flex: 0 0 auto;
    }
}

/* vertical variant */

.menu-list.-vertical {
    & {
        text-align: left;
    }

    > .menu-list_item {
        float: none;
    }
}

/* vertical reverse variant */

.menu-list.-vertical.-reverse {
    & {
        text-align: right;
    }
}

/* tier variant */

.menu-list[class*="-tier"] {
    & {
        min-width: 100%;
    }
}

/* tier accordion variant */

.menu-list[class*="-tier"].-accordion {
    & {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.25s, visibility 0s 0.25s;
        visibility: hidden;
        width: 100%;
    }

    .menu-list_item.-parent.is-active > & {
        max-height: remify(500, 16);
        transition: max-height 0.25s;
        visibility: visible;
    }
}

/* tier overlay variant */

.menu-list[class*="-tier"].-overlay {
    & {
        left: -999em;
        opacity: 0;
        position: absolute;
        top: -999em;
        transition: left 0s 0.15s, opacitwy 0.15s, top 0s 0.15s, visibility 0s 0.15s;
        visibility: hidden;
        width: remify(250, 16);
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        opacity: 1;
        transition: opacity 0.15s;
        visibility: visible;
    }
}

/* tier variant */

.menu-list[class*="-tier"].-overlay.-reverse {
    & {
        transition: opacity 0.15s, right 0s 0.15s, top 0s 0.15s;
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: auto;
        right: 0;
        transition: opacity 0.15s;
    }
}

/* tier1 overlay variant */

.menu-list.-tier1.-overlay {
    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: 0;
        top: 100%;
    }

    > .menu-list_item.is-active > .menu-list_toggle > .icon {
        transform: rotate(-90deg);
    }
}

/* tier1 overlay reverse variant */

.menu-list.-tier1.-overlay.-reverse {
    & {
        left: auto;
        right: 999em;
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: auto;
        right: 0;
    }

    > .menu-list_item.is-active > .menu-list_toggle > .icon {
        transform: rotate(90deg);
    }
}

/* tier2 overlay variant */

.menu-list.-tier2.-overlay {
    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: 100%;
        top: 0;
    }
}

/* tier2 overlay reverse variant */

.menu-list.-tier2.-overlay.-reverse {
    & {
        left: auto;
        right: 999em;
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: auto;
        right: 100%;
    }
}

/* ============================ CUSTOM VARIANTS =========================== */

/* mega variant */

.menu-list_container.-mega {
    & {
        display: flex;
        left: -999em;
        opacity: 0;
        position: absolute;
        right: 999em;
        top: -999em;
        transition: left 0s 0.15s, opacity 0.15s, right 0s 0.15s, top 0s 0.15s, visibility 0s 0.15s;
        visibility: hidden;
    }

    .menu-list_item.-mega.-parent:hover > &,
    .menu-list_item.-mega.-parent.is-active > & {
        left: 0;
        opacity: 1;
        right: 0;
        top: 100%;
        transition: opacity 0.15s;
        visibility: visible;
    }

    .menu-list.-tier1 {
        flex: 1 1 0%;
    }

    .menu-list.-tier2 {
        flex: 0 0 100%;
        min-width: 0;
    }
}

/* meta variant */

.menu-list.-meta {
    & {
        color: $foreground_alt;
        font-display: swap;
        font-family: $heading-font;
        font-size: remify(14, 16);
        font-weight: 700;
        text-align: left;
        text-transform: uppercase;
    }

    .menu-list_item:not(:last-child) {
        margin-right: remify(20, 14);
    }

    .menu-list_link {
        color: $foreground_alt;
        display: inline-block;
    }

    .menu-list_link:hover {
        color: lighten($foreground_alt, 10);
    }
}

/* navigation variant */

.menu-list.-navigation {
    & {
        font-display: swap;
        font-family: $body-font;
        font-size: remify(20, 16);
        font-weight: 700;
        line-height: remify(29, 20);
        text-transform: uppercase;
    }

    &.-vertical::before,
    &.-vertical::after {
        content: "\0020";
        display: block;
        height: remify(7.5, 20);
        width: 100%;
    }

    .menu-list_link,
    .menu-list_toggle {
        color: $secondary;
        padding: remify(20 20, 20);
        transition: background-color 0.15s;
    }

    &.-vertical .menu-list_link,
    &.-vertical .menu-list_toggle {
        padding-bottom: remify(7.5, 20);
        padding-top: remify(7.5, 20);
    }

    .menu-list_link:focus,
    .menu-list_link:hover,
    > .menu-list_item.-parent:hover > .menu-list_link,
    > .menu-list_item.-parent.is-active > .menu-list_link,
    .menu-list_toggle:focus,
    .menu-list_toggle:hover,
    .menu-list_item.-parent:hover > .menu-list_toggle,
    .menu-list_item.-parent.is-active > .menu-list_toggle {
        background-color: lighten($primary, 10);
        color: $secondary;
    }

    // tier 1

    .menu-list.-tier1 {
        background-color: lighten($primary, 10);
        font-size: remify(18, 20);
    }

    .menu-list.-tier1::before,
    .menu-list.-tier1::after {
        content: "\0020";
        display: block;
        height: remify(6.75, 18);
        width: 100%;
    }

    .menu-list.-tier1.-mega {
        max-width: remify(250, 18);
    }

    .menu-list.-tier1.-mega:not(:last-child) {
        border-right: remify(1) solid lighten(lighten($primary, 10), 10);
    }

    .menu-list.-tier1 .menu-list_link,
    .menu-list.-tier1 .menu-list_toggle {
        color: $light;
        padding: remify(6.75 20, 18);
        transition: background-color 0.15s;
    }

    .menu-list.-tier1 .menu-list_link:focus,
    .menu-list.-tier1 .menu-list_link:hover,
    .menu-list.-tier1:not(.-mega) > .menu-list_item.-parent:hover > .menu-list_link,
    .menu-list.-tier1:not(.-mega) > .menu-list_item.-parent.is-active > .menu-list_link,
    .menu-list.-tier1 .menu-list_toggle:focus,
    .menu-list.-tier1 .menu-list_toggle:hover,
    .menu-list.-tier1:not(.-mega) > .menu-list_item.-parent:hover > .menu-list_toggle,
    .menu-list.-tier1:not(.-mega) > .menu-list_item.-parent.is-active > .menu-list_toggle {
        background-color: lighten(lighten(lighten($primary, 10), 10), 10);
        color: $light;
    }

    // tier 2

    .menu-list.-tier2 {
        font-size: remify(16, 18);
    }

    .menu-list.-tier2:not(.-mega)::before,
    .menu-list.-tier2::after {
        content: "\0020";
        display: block;
        height: remify(4, 16);
        width: 100%;
    }

    .menu-list.-tier2:not(.-mega) {
        background-color: lighten(lighten(lighten($primary, 10), 10), 10);
    }

    .menu-list.-tier2 .menu-list_link,
    .menu-list.-tier2 .menu-list_toggle {
        color: $light;
        padding: remify(4 20, 16);
    }

    .menu-list.-tier2 .menu-list_link:focus,
    .menu-list.-tier2 .menu-list_link:hover,
    .menu-list.-tier2:not(.-mega) > .menu-list_item.-parent:hover > .menu-list_link,
    .menu-list.-tier2:not(.-mega) > .menu-list_item.-parent.is-active > .menu-list_link,
    .menu-list.-tier2 .menu-list_toggle:focus,
    .menu-list.-tier2 .menu-list_toggle:hover,
    .menu-list.-tier2:not(.-mega) > .menu-list_item.-parent:hover > .menu-list_toggle,
    .menu-list.-tier2:not(.-mega) > .menu-list_item.-parent.is-active > .menu-list_toggle {
        background-color: lighten(lighten(lighten(lighten($primary, 10), 10), 10), 10);
    }

    .menu-list.-tier2 .menu-list_link::before {
        content: "\002D\0020\0020";
    }
}

/* pagination variant */

.menu-list.-pagination {
    & {
        font-display: swap;
        font-family: $body-font;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(18, 16) remify(26, 16);
        font-weight: 400;
    }

    .menu-list_item:not(:last-child) {
        margin-right: remify(8, 18);
    }

    .menu-list_link.-current {
        color: $foreground;
        cursor: auto;
    }
}

/* social variant */

.menu-list.-social {
    & {
        font-family: $body-font;
        font-size: remify(20, 16);
        line-height: remify(29, 20);
    }

    .menu-list_link {
        color: $secondary;
        padding: remify(20 10, 20);
        transition: background-color 0.15s;
    }

    .menu-list_link:focus,
    .menu-list_link:hover {
        background-color: lighten($primary, 10);
        color: $secondary;
    }
}

/* submenu variant */

.menu-list.-submenu {
    & {
        font-display: swap;
        font-family: $heading-font;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(27, 16) remify(37, 16);
        font-weight: 700;
        line-height: remify(36, 27);
    }

    .menu-list_link {
        color: $primary;
        padding: remify(7 0, 27);
    }

    .menu-list_link:focus,
    .menu-list_link:hover {
        color: lighten($primary, 10);
    }

    .menu-list.-tier1 {
        font-family: $body-font;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(18, 27) remify(20, 37);
        line-height: remify(26, 18);
    }

    .menu-list.-tier1 .menu-list_link {
        color: $accent;
        padding: remify(6.5 0, 18);
    }

    .menu-list.-tier1 .menu-list_link:focus,
    .menu-list.-tier1 .menu-list_link:hover {
        color: lighten($accent, 10);
    }

    .menu-list.-tier2 {
        font-range: $xs-break $xl-break;
        font-size: responsive remify(14, 18) remify(16, 20);
        line-height: remify(17.5, 14);
    }

    .menu-list.-tier2 .menu-list_link {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23AE3D31' d='M25.3,16c0,0.3-0.1,0.5-0.3,0.7L10.1,31.7C9.9,31.9,9.6,32,9.3,32c-0.3,0-0.5-0.1-0.7-0.3L7,30.1c-0.2-0.2-0.3-0.5-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7L19.6,16L7,3.4C6.8,3.2,6.7,2.9,6.7,2.7S6.8,2.1,7,1.9l1.6-1.6C8.8,0.1,9.1,0,9.3,0c0.3,0,0.5,0.1,0.7,0.3L25,15.3C25.2,15.5,25.3,15.7,25.3,16z' /%3E%3C/svg%3E%0A") remify(0 7, 14) / remify(10 10, 14) no-repeat;
        color: $foreground;
        padding: remify(3.5 0 3.5 14, 14);
    }

    .menu-list.-tier2 .menu-list_link:focus,
    .menu-list.-tier2 .menu-list_link:hover {
        color: lighten($foreground, 10);
    }
}
